<template>
  <v-container fluid>
    <v-card elevation="2">
      <v-card-text>
        <h1><v-icon class="iconTitle">mdi-bell</v-icon> Noticias y avisos</h1><br>
        <v-layout>
          <v-flex xs12>
            <v-expansion-panels v-model="panel">
              <v-expansion-panel>
                <v-expansion-panel-header disable-icon-rotate>
                  <h2>Avisos urgentes</h2>
                  <template v-slot:actions>
                    <v-icon color="yellow">
                      mdi-alert
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-layout>
                    <v-flex xs6>
                      <v-select
                        chips
                        label="Productor/es que recibirán el aviso"
                        multiple
                        outlined
                        v-model="selectClient"
                        :items="this.$store.state.productor"
                        item-text="nombre"
                        item-value="id"
                      ></v-select>
                    </v-flex>
                    <v-flex xs6>
                      <v-textarea
                        outlined
                        label="Mensaje a enviar"
                        v-model="aviso"
                      ></v-textarea>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-btn class="btnCustom save" @click="sendAviso"><v-icon class="btnIcon">mdi-send</v-icon> Enviar</v-btn>
                  </v-layout>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header disable-icon-rotate>
                  <h2>Noticias</h2>
                  <template v-slot:actions>
                    <v-icon color="blue">
                      mdi-information
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-layout>
                    <v-flex xs3>
                      <v-file-input 
                        label="Imagen de la noticia"
                        outlined
                        @change="previewImgNoticia"
                        v-model="imgNoticia"
                        >
                      </v-file-input>
                    </v-flex>
                    <v-flex xs3>
                      <v-text-field
                        label="Título"
                        outlined
                        v-model="tituloNoticia"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs6>
                      <v-text-field
                        label="Resumen"
                        outlined
                        v-model="resumenNoticia"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs2>
                      <v-img class="imgNoticia" contain :src="url"></v-img>
                    </v-flex>
                    <v-spacer></v-spacer>
                    <v-flex xs9>
                      <quillEditor ref="editor" v-model="content"></quillEditor>
                    </v-flex>
                  </v-layout>
                  <v-layout style="margin-top: 5%;">
                    <v-btn class="btnCustom save" @click="saveNoticia"><v-icon class="btnIcon">mdi-table-arrow-right</v-icon> Guardar en el tablón</v-btn>
                  </v-layout>
                  <hr>
                  <div class="ListCard">
                    <h3 v-if="noticias.length > 0">Noticias en el tablón</h3><br>
                    <v-row>
                      <v-col class="col-md-3 col-xs-12" v-for="i in noticias" :key="i.id">
                        <v-card>
                          <v-img
                            class="white--text align-end"
                            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                            :src="i.imagen"
                          >
                            <v-card-title>{{ i.titulo }}</v-card-title>
                          </v-img>

                          <v-card-subtitle>
                            {{ $store.state.user }}
                            <v-btn text style="float:right" @click="deleteNoticia(i.id)"><v-icon>mdi-delete</v-icon></v-btn>
                            <v-btn text style="float:right" @click="editNoticia(i.id)"><v-icon>mdi-pencil</v-icon></v-btn>
                          </v-card-subtitle>

                          <v-card-text>
                            <div>{{ i.resumen }}</div>
                          </v-card-text>

                        </v-card>
                      </v-col>
                    </v-row>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
    <showMessage ref="snackbar" />
    <showDialog ref="dialog" />
  </v-container>
</template>

<script>
import {BASE_URL} from '../components/constants.js'
import genericReload from '../components/genericReload'
import showMessage from '../components/Messages.vue'
import showDialog from '../components/Dialog.vue'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import * as Quill from 'quill'
import { ImageDrop } from 'quill-image-drop-module'
import ImageResize from 'quill-image-resize-module-plus'
Quill.register('modules/imageDrop', ImageDrop)
Quill.register('modules/imageResize', ImageResize)
export default {
  name: 'Noticias',
  data () {
    return {
      rules: {
        required: [val => (val || '').length > 0 || 'Este campo es obligatorio'],
      },
      user: this.$store.state.user,
      panel: 0,
      imgNoticia: null,
      url: null,
      content: "",
      noticias: [],
      editando: 0,
      tituloNoticia: '',
      resumenNoticia: '',
      selectClient: null,
      aviso: ''
    }
  },
  components: {
    quillEditor,
    ImageDrop,
    ImageResize,
    showMessage,
    showDialog,
  },
  mounted(){
    this.read();
  },
  methods:{
    previewImgNoticia(){
      if(this.imgNoticia){
        this.url = URL.createObjectURL(this.imgNoticia);
      }else{
        this.url = null;
      }
    },
    async getImg(id){
      const response = await genericReload(this.$store.state.auth, `noticia/${id}/imagen`);
      if (response.status === 200) {
        const blob = await response.blob()
        let urlCreator = window.URL || window.webkitURL;
        let imageUrl = urlCreator.createObjectURL(blob);
        return imageUrl;
      }
    },
    async read(){
      this.noticias = [];
      const response = await genericReload(this.$store.state.auth, `noticia`);
      if (response.status === 200) {
        const json = await response.json();
        this.noticias = json.body;
        for (let i = 0; i < this.noticias.length; i++) {
          this.noticias[i].imagen = await this.getImg(this.noticias[i].id);
        }
      }else{
        this.$refs.snackbar.setNew(true, "ERROR. No se han podido cargar los datos", "Cerrar", "red");
      }
    },
    async saveNoticia(){
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+this.$store.state.auth
      };
      let response;
      let formData = new FormData();
      formData.append('imagen', this.imgNoticia);
      formData.append('cuerpo', this.content);
      formData.append('resumen', this.resumenNoticia);
      formData.append('titulo', this.tituloNoticia);
      const headersLogo = {
        'Authorization': 'Bearer '+this.$store.state.auth
      };
      if(this.editando == 0){
        response = await fetch(
          `${BASE_URL}/noticia`,
          {
            method: 'POST',
            headers: headersLogo,
            body: formData,
          }
        );
      }else{
        response = await fetch(
          `${BASE_URL}/noticia/${this.editando}`,
          {
            method: 'PUT',
            headers: headersLogo,
            body: formData,
          }
        );
      }
      switch (response.status) {
        case 200:
          this.$refs.snackbar.setNew(true, "Datos guardados correctamente", "Ok", "green");
          this.clear();
          this.read();
          break;
        default:
          this.$refs.snackbar.setNew(true, "ERROR. Datos no guardados", "Cerrar", "red");
          break;
      }
    },
    clear(){
      this.imgNoticia = null;
      this.url = null;
      this.content = "";
      this.editando = 0;
      this.tituloNoticia = '';
      this.resumenNoticia = '';
    },
    async editNoticia(id){
      this.editando = id;
      const response = await genericReload(this.$store.state.auth, `noticia/${id}`);
      if (response.status === 200) {
        const json = await response.json();
        this.content = json.body[0].cuerpo;
        this.tituloNoticia = json.body[0].titulo;
        this.resumenNoticia = json.body[0].resumen;
        this.url = await this.getImg(json.body[0].id);
      }else{
        this.$refs.snackbar.setNew(true, "ERROR. No se han podido cargar los datos", "Cerrar", "red");
      }
    },
    async deleteNoticia(id){
      this.$refs.dialog.setNew("Eliminar noticia", "¿Estás seguro de querer quitar esta noticia del tablón?", 0).then(async(confirm) => {
        if(confirm.response){
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+this.$store.state.auth
          };

          const response = await fetch(
            `${BASE_URL}/noticia/${id}`,
            {
              method: 'DELETE',
              headers: headers,
            }
          );
          switch (response.status) {
            case 200:
              this.$refs.snackbar.setNew(true, "Noticia eliminada correctamente", "Ok", "green");
              this.read();
              break;
            default:
              this.$refs.snackbar.setNew(true, "ERROR. Noticia no eliminada", "Cerrar", "red");
              break;
          }
        }
      });
    },
    async sendAviso(){
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+this.$store.state.auth
      };
      this.loading = true;
      if(this.selectClient != null && this.selectClient.length>0 && this.aviso != ''){
        let data, response;
        let ok = 0, error = 0;
        for(let i=0; i<this.selectClient.length; i++){
          data = {
            idProductor: this.selectClient[i],
            mensaje: this.aviso,
          };
          response = await fetch(
            `${BASE_URL}/aviso`,
            {
              method: 'POST',
              headers: headers,
              body: JSON.stringify(data),
            }
          );
          switch (response.status) {
            case 200:
              ok++;
              break;
            default:
              error++;
              break;
          }
        }
        this.loading = false;
        if(ok == this.selectClient.length){
          this.$refs.dialog.setNew("Resultado del envío de avisos", "Se han enviado todos los avisos correctamente. Avisos enviados: "+ok, 0);
        }else{
          this.$refs.dialog.setNew("Resultado del envío de avisos", "Algún aviso no se ha podido enviar. Avisos enviados correctamente: "+ok+". Avisos no enviados: "+error, 0);
        }
      }else{
        this.$refs.snackbar.setNew(true, "ERROR. Faltan datos importantes para llevar a cabo la liquidación", "Cerrar", "red");
        this.loading = false;
      }
    },
  }
}
</script>
<style scoped>
.v-input{
  margin-right: 1em;
}
hr{
  margin: 3% 0;
  color: #91dc5a;
}
.ListCard .v-card{
  text-align:left;
  transition: transform .2s;
}
.ListCard .v-card:hover {
  transform: scale(1.05);
  z-index: 2;
}
.ListCard .v-card__subtitle {
  font-weight: bold;
}
.ListCard .v-chip {
  float:right;
}
</style>